/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 100000, price: 100000 },
    { get: 250000, price: 249000 },
    { get: 1000000, price: 996000, hot: 1 },
    { get: 2500000, price: 2490000, hot: 1 },
    { get: 5000000, price: 4980000, hot: 1 },
    { get: 10000000, price: 9960000, hot: 1 },
    { get: 25000000, price: 24900000, hot: 1 },
    { get: 50000000, price: 50000000 },
    { get: 100000000, price: 100000000 },
  ],
  firstRechargeAmount: 100000,
  firstRechargedWithdrawNeedBets: 500000,
  defaultDepositGot: 2500,
  defaultDepositPrice: 2490,
  minDepositAmount: 100000,
  maxDepositAmount: 100000000,
  minWithdrawAmount: 250000,
  maxWithdrawAmount: 50000000,
  maxWithdrawDailyAmount: 300000000,
  inviteWheel:{
    targetAmount: 500000,
  },
  luckyDraw:{
    marqueeAmoundList: [2000, 5000, 8000, 10000, 50000, 25000, 8000, 2000, 5000, 5000, 2000, 2000],
  },
  addHomeScreenRewardFrom: 100,
  addHomeScreenRewardTo: 10000,
};
