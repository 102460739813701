/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
    locationKey: "UG",
    currencyCode: "UGX",
    currencyName: "Ugandan Shilling",
    indexBannerDepositImg: require("@/assets/images/home/index_banner_deposit_ug.png").default,
    indexBannerDeposit2Img: require("@/assets/images/home/index_banner_deposit2.png").default,
    indexBannerDrawImg: require("@/assets/images/home/index_banner_draw.png").default,
    indexBannerinviteImg: require("@/assets/images/home/index_banner_invite_ug.jpg").default,
    indexBannerLuckyWheelImg: require("@/assets/images/home/index_banner_lucky_wheel_ug.png").default,
    indexBannerInstallImg: require("@/assets/images/home/index_banner_install_ke.png").default,
    downloadTipTextImg: require("@/assets/images/download/top_zi_ke.png").default,
}