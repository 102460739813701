import { FromInstalled, FromIPhone } from "@/utils/BroswerHelper";
import { SendCMD } from "@/utils/HTTPRequest";
import { DeepEqual } from "@/utils/JSTool";
import { createContext, useEffect, useState } from "react";

const defaultPersist = {
  dataLoaded: false,
  debug: false,
  language: "en",
  location: "KE",
  downloadTipClosed: FromInstalled || FromIPhone,
  invitePromotionOpenDay: -1,
  funnelStepsFinishAt: {},
  funnelStepsUploadedAt: 0,
};

// 创建一个 Context
export const PersistContext = createContext(defaultPersist);

let untrackedData = defaultPersist;

// 创建一个 DataProvider 组件
export const PersistProvider = ({ children }) => {
  const [data, setData] = useState(untrackedData);
  const [expires, setExpires] = useState({});

  useEffect(() => {
    loadPersistDataOnce();
  }, []);
  const reloadUserInfo = (token) => {
    if (token || (data.user_info && data.user_info.token)) {
      SendCMD("getMe", {
        token: token ? token : data.user_info.token,
      }).then((res) => {
        if (!res.status) {
          //账号过期清空账号
          console.log(res.errdesc);
          setPersist("user_info", null);
          setPersist("gatesvr", null);
        } else {
          console.log(res.player_info);
          if (res.player_info) {
            setPersist("user_info", res.player_info);
            setPersist("gatesvr", res.gatesvr);
          }
        }
        console.log(res);
      });
    }
  };

  const loadPersistDataOnce = () => {
    if (untrackedData.dataLoaded) {
      return;
    }
    let persistStr = localStorage.getItem("persist");
    console.log("persistStr loaded:", persistStr);
    if (persistStr) {
      try {
        let persistData = JSON.parse(persistStr);
        for (let key in persistData) {
          if (typeof persistData[key] == "undefined" || persistData[key] == null) {
            delete persistData[key];
          }
        }
        untrackedData = Object.assign({}, untrackedData, persistData);
      } catch (e) {
        console.error(e);
      }
    }
    let expiresStr = localStorage.getItem("expires");
    if (expiresStr) {
      try {
        let expires = JSON.parse(expiresStr);
        setExpires(expires);
      } catch (e) {
        console.error(e);
      }
    }
    untrackedData.dataLoaded = true;
    setData(Object.assign({}, untrackedData));
    if (untrackedData.user_info && untrackedData.user_info.token) {
      reloadUserInfo(untrackedData.user_info.token);
    }
  };

  const setPersist = (key, value, expireSeconds) => {
    console.log("setPersist", key, value, expireSeconds);
    loadPersistDataOnce();
    if (DeepEqual(untrackedData[key], value)) return;
    untrackedData[key] = value;
    if (expireSeconds && expireSeconds > 0) {
      let expireData = { ...expires };
      expireData[key] = Date.now() + expireSeconds * 1000;
      setExpires(expireData);
      localStorage.setItem("expires", JSON.stringify(expireData));
    }
    setData(() => Object.assign({}, untrackedData));
    console.log(untrackedData);
    localStorage.setItem("persist", JSON.stringify(untrackedData));
  };

  const getPersist = (key) => {
    loadPersistDataOnce();
    let value = data[key];
    if (typeof value == "undefined") return null;
    let keyExpire = expires[key];
    if (keyExpire && keyExpire < Date.now()) {
      let dataTmp = { ...data };
      delete dataTmp[key];
      delete untrackedData[key];
      let expiresTmp = { ...expires };
      delete expiresTmp[key];
      localStorage.setItem("persist", JSON.stringify(dataTmp));
      localStorage.setItem("expires", JSON.stringify(expiresTmp));
      return null;
    }
    return value;
  };

  return (
    <PersistContext.Provider value={{ getPersist, setPersist, reloadUserInfo }}>{children}</PersistContext.Provider>
  );
};
