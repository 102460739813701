const payments = [
  {
    text: "MTN",
    enable: true,
    type: "MTN_UGANDA",
    merchantId: 9,
    img: "../assets/images/home/MTN.png",
    checkPrecedence: (phone) => {
      return true;
    },
  },
  {
    text: "Airtel",
    enable: true,
    type: "AIRTEL_UGANDA",
    merchantId: 9,
    img: "../assets/images/home/airtel_money.png",
    checkPrecedence: (phone) => {
      return true;
    },
  },
];
export default {
  payments: payments.filter((payment) => payment.enable),
};
